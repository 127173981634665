/* ==========================================================================
   & IE9
   ========================================================================== */

.nav {
  &__list {
    display:table;
    min-height:6.2rem;
    &.left {
      float:left;
    }
    &.right {
      float:right;
    }
  }
  &__item {
    display:table-cell;
    vertical-align:middle;
  }
}